/* @import "dashboard.css";
@import "tabler.css";

.icon_star:before{font-family:feather !important;font-style: normal;font-weight: 400;font-variant: normal;text-transform: none;line-height: 1;content: "\e9d3" !important;}
.info-prestation{font-size: 12px; font-style: italic;}
div.rating{float: left;}
div.review_card .review_content .add_bottom_15>em{float: right;}
.add_bottom_15{padding-bottom: 10px;}
.signal-abus{display: none;}
.owl-carousel img{height: 100px;}
.owl-carousel .item{width: 150px; float: left; padding: 5px;}
#images-in{display: none;}
.img-review-dialog{padding-top: 1rem;}
.img-review-dialog h3{font-size: 1rem;}
.rating {color:#999;font-size:12px;font-size:.75rem;}.rating i {width:20px;-webkit-border-radius:3px;-moz-border-radius:3px;-ms-border-radius:3px;border-radius:3px;height:20px;font-size:12px;font-size:.75rem;display:inline-block;background-color:#f3bf12;color:#fff;line-height:20px;text-align:center;margin-right:2px;}.rating i.empty-half {background-color:rgba(243,191,18,1);background:-moz-linear-gradient(left,rgba(243,191,18,1) 0%,rgba(243,191,18,1) 50%,rgba(204,204,204,1) 50%,rgba(204,204,204,1) 100%);background:-webkit-gradient(left top,right top,color-stop(0%,rgba(243,191,18,1)),color-stop(50%,rgba(243,191,18,1)),color-stop(50%,rgba(204,204,204,1)),color-stop(100%,rgba(204,204,204,1)));background:-webkit-linear-gradient(left,rgba(243,191,18,1) 0%,rgba(243,191,18,1) 50%,rgba(204,204,204,1) 50%,rgba(204,204,204,1) 100%);background:-o-linear-gradient(left,rgba(243,191,18,1) 0%,rgba(243,191,18,1) 50%,rgba(204,204,204,1) 50%,rgba(204,204,204,1) 100%);background:-ms-linear-gradient(left,rgba(243,191,18,1) 0%,rgba(243,191,18,1) 50%,rgba(204,204,204,1) 50%,rgba(204,204,204,1) 100%);background:linear-gradient(to right,rgba(243,191,18,1) 0%,rgba(243,191,18,1) 50%,rgba(204,204,204,1) 50%,rgba(204,204,204,1) 100%);}.rating i.empty {background-color:#ccc;}.rating em {display:inline-block;margin-left:10px;}.rating_feature h5 {font-size:15px;font-size:1rem;}.rating_feature span strong,.rating_feature span b {width:120px;display:inline-block;}.rating_feature span {width:100%;display:block;margin-bottom:5px;color:#999;font-size:12px;font-size:.75rem;}.rating_feature span i {width:13px;height:13px;font-size:12px;font-size:.75rem;display:inline-block;color:#f3bf12;line-height:20px;text-align:center;margin-right:2px;}.rating_feature span em {display:inline-block;margin-left:5px;} */